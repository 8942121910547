<template>
  <PermissionForm mode="Tambah" module="Permission"> </PermissionForm>
</template>

<script>
import PermissionForm from './form';

const PermissionAdd = {
  name: 'PermissionAdd',
  components: { PermissionForm },
};

export default PermissionAdd;
</script>
